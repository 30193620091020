const HeaderLinks: any[] = [
  {
    title: "Profile",
    path: "/dashboard/account",
  },
  {
    title: "Favorites",
    path: "/dashboard/favorites",
  },
  {
    title: "Music Taste",
    path: "/dashboard/music-taste",
  },
  {
    title: "Settings",
    path: "/dashboard/settings",
  },
];
export default HeaderLinks;
