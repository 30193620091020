import { API } from "../router/apiEndpoints";

export const fetchMediaData = async (limit = 3, mode = "", genre = "", page = 1) => {
    try {
        const response = await fetch(`${API.HOST}${API.VIDEOS.GET}?page=${page}`, {
            method: 'GET',
            headers: {
                'Application': 'application/json',
                'Content-Type': 'application/json',
                'X-Page': `${page}`, // Added the X-Page header for pagination
                'X-Limit': `${limit}`,    // Fixed limit, you can adjust as needed
                'X-Mode': `${mode}`,
                'X-Genre': `${genre}`,
            },
        });

        if (!response.ok) {
            console.error('Failed to fetch data:', response.statusText);
            return { data: [], pagination: { next_page_url: null } }; // Return empty data with no next page
        }

        const data = await response.json();
        // console.log(`Fetched Media data: `, data);
        return data; // Expecting the API to return paginated data with a "data" and "pagination" structure
    } catch (error) {
        console.error('Error fetching media data:', error);
        return { data: [], pagination: { next_page_url: null } }; // Return empty data on error
    }
};

export const fetchMediaDataByArtist = async (limit = 3, artist = 0, page = 1) => {
    try {
        const response = await fetch(`${API.HOST}${API.VIDEOS.GET}?page=${page}`, {
            method: 'GET',
            headers: {
                'Application': 'application/json',
                'Content-Type': 'application/json',
                'X-Page': `${page}`, // Added the X-Page header for pagination
                'X-Limit': `${limit}`,    // Fixed limit, you can adjust as needed
                'X-Artist': `${artist}`,
            },
        });

        if (!response.ok) {
            console.error('Failed to fetch data:', response.statusText);
            return { data: [], pagination: { next_page_url: null } }; // Return empty data with no next page
        }

        const data = await response.json();
        // console.log(`Fetched Media data: `, data);
        return data; // Expecting the API to return paginated data with a "data" and "pagination" structure
    } catch (error) {
        console.error('Error fetching media data:', error);
        return { data: [], pagination: { next_page_url: null } }; // Return empty data on error
    }
};

export const fetchVideoTypeMediaData = async (limit = 3, mode = "", genre = "", page = 1) => {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        const flagValue = urlParams.get('flag');

        const response = await fetch(`${API.HOST}${API.VIDEOS.GET}?page=${page}`, {
            method: 'GET',
            headers: {
                'Application': 'application/json',
                'Content-Type': 'application/json',
                'X-Page': `${page}`, // Added the X-Page header for pagination
                'X-Limit': `${limit}`,    // Fixed limit, you can adjust as needed
                'X-Mode': `${mode}`,
                'X-Genre': `${genre}`,
                'X-Video-Type': 'video',
                'X-Video-Flag': flagValue ? flagValue : '',
            },
        });

        if (!response.ok) {
            console.error('Failed to fetch data:', response.statusText);
            return { data: [], pagination: { next_page_url: null } }; // Return empty data with no next page
        }

        const data = await response.json();
        // console.log(`Fetched Media data: `, data);
        return data; // Expecting the API to return paginated data with a "data" and "pagination" structure
    } catch (error) {
        console.error('Error fetching media data:', error);
        return { data: [], pagination: { next_page_url: null } }; // Return empty data on error
    }
};

export const fetchAudioTypeMediaData = async (limit = 3, mode = "", genre = "", page = 1) => {
    try {
        const response = await fetch(`${API.HOST}${API.VIDEOS.GET}?page=${page}`, {
            method: 'GET',
            headers: {
                'Application': 'application/json',
                'Content-Type': 'application/json',
                'X-Page': `${page}`, // Added the X-Page header for pagination
                'X-Limit': `${limit}`,    // Fixed limit, you can adjust as needed
                'X-Mode': `${mode}`,
                'X-Genre': `${genre}`,
                'X-Video-Type': 'audio'
            },
        });

        if (!response.ok) {
            console.error('Failed to fetch data:', response.statusText);
            return { data: [], pagination: { next_page_url: null } }; // Return empty data with no next page
        }

        const data = await response.json();
        console.log(`Fetched Media data: `, data);
        return data; // Expecting the API to return paginated data with a "data" and "pagination" structure
    } catch (error) {
        console.error('Error fetching media data:', error);
        return { data: [], pagination: { next_page_url: null } }; // Return empty data on error
    }
};

export const fetchHighlights = async () => {
    try {
        const response = await fetch(`${API.HOST}${API.HIGHLIGHTS.GET}`, {
            method: 'GET',
            headers: {
                'Application': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            // Logs the error if response is not successful
            console.error('Failed to fetch data:', response.statusText);
            return null; // Return null or some fallback data if fetch fails
        }

        const data = await response.json();
        console.log('Fetched media data:', data);
        return data; // Return the fetched data
    } catch (error) {
        console.error('Error fetching media data:', error);
        return null; // Return null in case of an error
    }
}

export const fetchArtistsList = async () => {
    try {
        const response = await fetch(`${API.HOST}${API.ARTISTS.GET}`, {
            method: 'GET',
            headers: {
                'Application': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            // Logs the error if response is not successful
            console.error('Failed to fetch data:', response.statusText);
            return null; // Return null or some fallback data if fetch fails
        }

        const data = await response.json();
        // console.log('Fetched media data:', data);
        return data; // Return the fetched data
    } catch (error) {
        console.error('Error fetching media data:', error);
        return null; // Return null in case of an error
    }
}

export const fetchGenresList = async () => {
    try {
        const response = await fetch(`${API.HOST}${API.GENRES.GET}`, {
            method: 'GET',
            headers: {
                'Application': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            // Logs the error if response is not successful
            console.error('Failed to fetch data:', response.statusText);
            return null; // Return null or some fallback data if fetch fails
        }

        const data = await response.json();
        // console.log('Fetched media data:', data);
        return data; // Return the fetched data
    } catch (error) {
        console.error('Error fetching media data:', error);
        return null; // Return null in case of an error
    }
}

export const fetchFavoriteVideos = async (limit = 3, mode = "", genre = "", page = 1) => {
      try {
        const response = await fetch(`${API.HOST}${API.VIDEOS.GET}?page=${page}`, {
            method: 'GET',
            headers: {
                'Application': 'application/json',
                'Content-Type': 'application/json',
                'X-Page': `${page}`, // Added the X-Page header for pagination
                'X-Limit': `${limit}`,    // Fixed limit, you can adjust as needed
                'X-Mode': `${mode}`,
                'X-Genre': `${genre}`,
            },
        });

        if (!response.ok) {
            console.error('Failed to fetch data:', response.statusText);
            return { data: [], pagination: { next_page_url: null } }; // Return empty data with no next page
        }

        const data = await response.json();
        // console.log(`Fetched Media data: `, data);
        return data; // Expecting the API to return paginated data with a "data" and "pagination" structure
    } catch (error) {
        console.error('Error fetching media data:', error);
        return { data: [], pagination: { next_page_url: null } }; // Return empty data on error
    }
}