import { Link, NavLink } from "react-router-dom";
import SvgColor from "../../components/custom/SvgColor";
import SidebarNavLinks from "./sidebarNavLinks";
import DarkModeToggle from "../../components/custom/DarkModeToggle";
import { useTheme } from "../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarOpen } from "../../redux/slice/globalSlice";
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Sidebar = () => {
  const { darkMode, toggleDarkMode } = useTheme();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((d: any) => d.global.sidebarOpen);
  useEffect(() => {
    const handleResize = () => {
      // Check if the window width is larger than the 'lg' breakpoint (1024px)
      const isLg = window.innerWidth >= 1024;
      // Update your variable or state here based on isLg
      // For example, you might want to dispatch an action or set local state
      dispatch(setSidebarOpen(false));
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Call the function initially to set the correct state on mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
  useEffect(() => {
    // Set body overflow based on sidebarOpen state
    if (sidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto"; // or 'visible' depending on your needs
    }

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [sidebarOpen]);
  return (
    <>
      {sidebarOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 z-30 max-lg:block hidden"
          onClick={() => dispatch(setSidebarOpen(false))}
        >
          <button
            className="absolute top-4 right-4"
            onClick={() => dispatch(setSidebarOpen(false))}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      )}
      <aside
        className={`fixed top-0 left-0 w-80 h-screen overflow-auto p-6 ${
          sidebarOpen ? "max-lg:bg-[#393939]" : "max-lg:-ml-80"
        } transition-all z-30`}
      >
        <Link to={"/"}>
          <SvgColor
            src="/img/wizmeek_logo.png"
            className="!h-8 !w-44"
            color={darkMode ? "white" : "black"} // Change color based on theme
          />
        </Link>
        <div className="space-y-4 mt-4">
          <span className="uppercase text-xs text-[#808191]">Menu</span>
          <ul className="space-y-4">
            {SidebarNavLinks.map((d: any, index: number) => (
              <li key={index}>
                <NavLink
                  key={index}
                  to={d.path}
                  className={({ isActive }) =>
                    `flex justify-start items-center gap-4 ${
                      isActive ? "active-class" : ""
                    }`
                  }
                >
                  {({ isActive }) => (
                    <>
                      <span
                        className={`flex justify-center items-center w-8 h-8 rounded-xl ${
                          isActive
                            ? "bg-[#C25757]"
                            : "bg-[#ebbc8d]"
                        }`}
                      >
                        {/* <SvgColor src={d.icon} className="!bg-white" /> */}
                        <FontAwesomeIcon icon={d.icon} />
                      </span>
                      <span
                        className={
                          isActive
                            ? "font-bold text-black dark:text-white"
                            : "text-[#808191] dark:text-[#808191]"
                        }
                      >
                        {d.title}
                      </span>
                    </>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="border-t border-[#34373C] py-4">
            <DarkModeToggle />
          </div>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
