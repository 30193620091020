const AboutUS = () => {
  return (
    <div className="px-4 text-black">
      <div className="max-w-screen-lg mx-auto space-y-4 rounded bg-[#e6dddd] mt-24 p-6">
        <h2 className="text-4xl font-semibold">About Us</h2>
        <p>
          At Wizmeek, we believe music has the power to bring people together
          and transform lives. Our passion is discovering and supporting
          independent artists who fill the world with their unique talents. We
          have created an online platform where music fans can easily explore
          and enjoy the latest tracks from rising stars in any genre
          imaginable—pop, hip-hop, R&B, Afrobeats, and more. You'll hear songs
          you can't find anywhere else. Our handpicked Wizmeek selections
          showcase only independent talent, not mainstream hits. We curate the
          best music videos so you can discover new favorites. Sit back and let
          our diverse playlists surprise and inspire you for hours on end. By
          providing a stage for independent musicians, we hope to change the
          entertainment world for the better. Talented artists deserve to have
          their voices heard, and music lovers deserve access to their
          soul-stirring songs. We are on a mission to create an entertainment
          revolution by bringing talented musicians and appreciative audiences
          together. We can't wait for you to join our community of music fans
          who live and breathe exhilarating indie songs. Let the music move you!
          Sign up now to start streaming the latest and greatest independent
          releases.
        </p>
      </div>
    </div>
  );
};
export default AboutUS;
