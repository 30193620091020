import NotificationItem from "./notificationItem";
import notifications from "../../../data/notifications.json";
const Notifications = () => {
  return (
    <div className="px-4">
      <div className="max-w-screen-md bg-[#5E5144] p-8 rounded-2xl">
        <h2 className="text-2xl font-medium">Notifications</h2>
        <div className="space-y-3 mt-4">
          {notifications.map((d: any, index: number) => (
            <NotificationItem {...d} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default Notifications;
