import { faHome, faVideo, faHeadphones, faMicrophoneLines, faUsers } from '@fortawesome/free-solid-svg-icons';

const SidebarNavLinks: any[] = [
  {
    icon: faHome,
    title: "Home",
    path: "/dashboard/home",
  },
  {
    icon: faVideo,
    title: "Music Videos",
    path: "/dashboard/music-videos",
  },
  {
    icon: faHeadphones,
    title: "Official Audio",
    path: "/dashboard/official-audio",
  },
  {
    icon: faMicrophoneLines,
    title: "Artists",
    path: "/dashboard/artists",
  },
  {
    icon: faUsers,
    title: "About US",
    path: "/dashboard/about-us",
  },
];
export default SidebarNavLinks;
