import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useRef, useState } from "react";

const Slide = (props: any) => {
  const { slideElements } = props;
  const swiperRef = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active slide index

  const onSlideChange = (swiper: any) => {
    setActiveIndex(swiper.activeIndex); // Update active index on slide change
  };

  const progressCircle = useRef<SVGSVGElement | null>(null);
  const progressContent = useRef<HTMLSpanElement | null>(null);

  const onAutoplayTimeLeft = (s: any, time: number, progress: number) => {
    if (progressCircle.current) {
      progressCircle.current.style.setProperty(
        "--progress",
        (1 - progress).toString()
      );
    }
    if (progressContent.current) {
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    }
  };

  const SwiperControl = () => (
    <div className="">
      <button
        className="cursor-pointer hover:opacity-75 absolute top-1/2 -translate-y-1/2 left-4 z-10 transform scale-75 min-[640px]:scale-100"
        onClick={() => {
          console.log("Slide Previous Clicked");
          swiperRef.current.swiper.slidePrev();
        }}
      >
        <svg
          width="20"
          height="45"
          viewBox="0 0 20 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.572046 24.3506L17.9077 44.6003C18.1078 44.8338 18.3419 44.9706 18.5847 44.9958C18.8275 45.0209 19.0698 44.9335 19.2853 44.7431C19.5008 44.5526 19.6813 44.2662 19.8073 43.9151C19.9333 43.5639 19.9999 43.1613 20 42.7508V2.25145C20.0002 1.84078 19.9337 1.43787 19.8078 1.08638C19.682 0.734893 19.5015 0.448234 19.2859 0.257473C19.0704 0.0667108 18.828 -0.0208779 18.5851 0.00419949C18.3421 0.0292768 18.1079 0.166064 17.9077 0.399735L0.572046 20.6494C0.395434 20.8574 0.251199 21.1342 0.151604 21.4564C0.0520089 21.7786 0 22.1366 0 22.5C0 22.8634 0.0520089 23.2214 0.151604 23.5436C0.251199 23.8658 0.395434 24.1426 0.572046 24.3506Z"
            fill="#CECECE"
          />
        </svg>
      </button>
      <button
        className="cursor-pointer hover:opacity-75 absolute top-1/2 -translate-y-1/2 right-4 z-10 transform scale-75 min-[640px]:scale-100"
        disabled={false}
        onClick={() => {
          console.log("Slide Next Clicked");
          swiperRef.current.swiper.slideNext();
        }}
      >
        <svg
          width="20"
          height="45"
          viewBox="0 0 20 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.71469 44.7451C0.930384 44.9344 1.17256 45.021 1.41522 44.9957C1.65788 44.9703 1.89187 44.8339 2.09207 44.6011L19.426 24.35C19.6031 24.1429 19.7478 23.8665 19.8478 23.5444C19.9478 23.2223 20 22.864 20 22.5004C20 22.1368 19.9478 21.7786 19.8478 21.4565C19.7478 21.1343 19.6031 20.8579 19.426 20.6508L2.09207 0.399762C1.89203 0.166183 1.65793 0.029404 1.41513 0.00423419C1.17233 -0.0209356 0.930075 0.0664635 0.714596 0.256968C0.499118 0.447472 0.318628 0.733822 0.192669 1.08501C0.06671 1.4362 8.21138e-05 1.83885 1.81114e-08 2.24936V42.7515C-4.00911e-05 43.1622 0.0665396 43.5651 0.192523 43.9166C0.318506 44.268 0.499086 44.5545 0.71469 44.7451Z"
            fill="#CECECE"
          />
        </svg>
      </button>
    </div>
  );

  return (
    <>
      <div className="relative w-full">
        {SwiperControl()}
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
            
          }}
          ref={swiperRef}
          navigation={false}
          modules={[Autoplay, Navigation]}
          onSlideChange={onSlideChange} // Add the slide change event
          onAutoplayTimeLeft={onAutoplayTimeLeft}
          className="mySwiper select-none"
        >
          {slideElements &&
            Array.isArray(slideElements) &&
            slideElements?.map((d: any, index: number) => (
              <SwiperSlide key={index}  className="max-md:!w-full">
                {d}
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      {/* Custom Indicators */}
      <div className="flex justify-center mt-1 gap-6">
        {slideElements &&
          Array.isArray(slideElements) &&
          slideElements.map((d: any, index: number) => (
            <span
              key={index}
              className={`w-3 h-3 rounded-full cursor-pointer transition-colors duration-300 ${
                activeIndex === index
                  ? "bg-[#A1A0A0]"
                  : "border-2 border-[#A1A0A0]"
              }`}
              onClick={() => {
                setActiveIndex(index);
                swiperRef.current.swiper.slideTo(index); // Navigate to the selected slide
              }}
            />
          ))}
      </div>
    </>
  );
};

export default Slide;
