export const selectHost = () => {
  const frontendHost = window.location.hostname;
  let backendHost;
  switch (frontendHost) {
    case "localhost":
      backendHost = "https://dev-app.wizmeek.com";
      break;
    case "wizmeek.com":
      backendHost = "https://dev-app.wizmeek.com";
      break;
    case "www.wizmeek.com":
      backendHost = "https://dev-app.wizmeek.com";
      break;
    default:
      backendHost = "http://localhost:8876";
      break;
  }
  return backendHost;
};
