import Slide from "./slide";
import MediaCard from "../../../components/global/mediaCard";
import { useEffect, useState } from "react";
import MediaDetail from "../../../components/global/mediaDetail";
import SlideCard from "./slideCard";
import { Helmet } from "react-helmet";
import { fetchHighlights, fetchMediaData } from "../../../helpers/fetcher";

const Home = () => {
  const [openItem, setOpenItem] = useState(false);
  const [data, setData] = useState(null);
  const [medias, setMedias] = useState<any[]>([]);
  const [sliders, setSliders] = useState<any[]>([]);
  const [slidesData, setSlidesData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [hasMore, setHasMore] = useState(true); // Indicates if more data is available
  const [isLoading, setIsLoading] = useState(false); // Prevents duplicate fetches

  const handleClick = (data: any) => {
    setData(data);
    setOpenItem(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // Fetch highlights and first page on mount
  useEffect(() => {
    const fetchInitialData = async () => {
      const highlights = await fetchHighlights();
      console.log({ highlights })
      setSliders(highlights);
  
      // Fetch the first page of media data
      await fetchMediaDataPaginated(6, "latest", "", 1, true);
    };
  
    fetchInitialData();
  }, []);

  const fetchMediaDataPaginated = async (
    limit: number,
    mode: string,
    genre: string,
    page: number,
    isInitialLoad = false
  ) => {
    if (isLoading || (!isInitialLoad && !hasMore)) return;
  
    setIsLoading(true);
    try {
      const response = await fetchMediaData(limit, mode, genre, page); // Ensure the correct page is always passed
      if (response.data.length > 0) {
        // For initial load, replace media data instead of appending
        if (isInitialLoad) {
          setMedias(response.data);
        } else {
          setMedias((prev) => [...prev, ...response.data]);
        }
  
        setHasMore(response.pagination.next_page_url !== null); // Update hasMore based on the API response
      } else {
        setHasMore(false); // No more data
      }
  
      // Update currentPage only if it's not the initial load
      if (!isInitialLoad) setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching media data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Prepare slides data whenever sliders change
  useEffect(() => {
    const prepareSlideData = (sliders: any[]) => {
      return sliders.map((slider: any) => {
        return slider.items.map((slide: any) => (
          <div className="cursor-pointer" onClick={() => handleClick(slide)}>
            <img src={slide.cover} className="w-full object-cover object-center rounded-t-2xl aspect-[345/236]" alt={slide.title} />
            <div className="text-sm p-3 min-[640px]:text-ellipsis min-[640px]:text-nowrap min-[640px]:overflow-hidden">
              <span className="text-sm font-medium text-[#B7B9D2]">{slide.artist} - </span> 
              <span className="text-sm font-medium text-white">{slide.title}</span>
            </div>
          </div>
        ));
      });
    };

    setSlidesData(prepareSlideData(sliders));
  }, [sliders]);

  // Handle infinite scroll
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100 && // Near bottom
        hasMore &&
        !isLoading
      ) {
        fetchMediaDataPaginated(6, "latest", "", currentPage + 1); // Increment the page
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage, hasMore, isLoading]); // Dependencies ensure correct behavior

  return (
    <>
      <Helmet>
        <title>Wizmeek</title>
        <meta
          name="description"
          content="Explore the world of indie music and discover talented artists who deserve more recognition. Find new music gems and support emerging artists."
        />
      </Helmet>
      <div>
        {openItem && <MediaDetail setOpen={setOpenItem} data={data} />}
          <>
            <h1 className="font-medium text-2xl pt-2">Wizmeek Highlights</h1>
            <div className="grid gird-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 mt-4 sm:w-full">
              {slidesData.map((slideElements, index) => (
                <SlideCard key={index} slideElements={slideElements}>
                  <div className="flex justify-between">
                    <button className="flex-1 rounded-l-2xl py-1 bg-black text-white">
                      All Genres
                    </button>
                    <button
                      className={`flex-1 rounded-r-2xl py-1 ${
                        index === 0
                          ? "bg-[#65A64E]"
                          : index === 1
                          ? "bg-[#E56116]"
                          : "bg-[#65A64E]"
                      } text-white`}
                    >
                      {["New", "Throwback", "Editor's Pick"][index] || "Other"}
                    </button>
                  </div>
                </SlideCard>
              ))}
            </div>
          </>
          
        <div className="mt-6">
          <h1 className="font-medium text-2xl">Based on your Taste</h1>
          <div className="mt-8">
            <div className="grid gird-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
              {medias.map((d: any, index: number) => (
                <div key={index}>
                  <MediaCard
                    {...d}
                    idx={index}
                    onClick={() => handleClick(d)}
                  />
                </div>
              ))}
              {isLoading && <p>Loading...</p>}
              {!hasMore && <p>No more media available.</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
