import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen bg-[#302D2A]">
      <div className="w-screen h-screen bg-cover bg-center fixed top-0 left-0">
        <img
          src="/img/shapes-original.png"
          alt="BG"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <Header />
      <main className="flex-grow pt-16 relative z-[1]">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
