import React, { useState } from 'react';

const Subscribe: React.FC = () => {
  const [email, setEmail] = useState<string>('');  // Ensure type string
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);  // Ensure type boolean

  const handleSubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    // Reset error and success messages
    setError(null);
    setSuccess(null);

    // Basic email validation
    if (!email) {
      setError('Please enter your email.');
      return;
    }

    console.log(email);  // Logs the current email before the fetch request

    setIsLoading(true); // Start loading

    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccess('Successfully subscribed!');
        setEmail(''); // Reset the input field
      } else {
        setError('Subscription failed. Please try again later.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto relative z-[1] mt-8 pb-10 px-4">
      <form onSubmit={handleSubscribe} className="mt-24 max-w-[480px] mx-auto flex justify-between">
        <input
          type="email"
          className="rounded-l-3xl bg-[#D9D9D9] text-black py-2 text-center flex-grow max-lg:w-20"
          placeholder="Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
            console.log('Email state:', e.target.value);  // Log email state as it changes
          }}
        />
        <button
          type="submit"
          className={`px-6 lg:px-8 rounded-r-3xl text-white ${
            isLoading
              ? 'bg-gray-500 cursor-not-allowed' // Loading state styling
              : success
              ? 'bg-green-500' // Success state styling
              : 'bg-black hover:bg-gray-800' // Default state styling
          }`}
          disabled={isLoading} // Disable button when loading
        >
          {isLoading ? (
            <span className="loader"></span> // Add your preloader animation here
          ) : success ? (
            <span className="check-icon"></span> // Add your checkmark animation here
          ) : (
            'Subscribe'
          )}
        </button>
      </form>

      {error && <p className="text-red-500 text-center mt-2">{error}</p>}
      {success && <p className="text-green-500 text-center mt-2">{success}</p>}

      <div className="md:text-center mt-4 text-lg text-white">
        <p>Subscribe to our mailing list to be updated until we officially launch.</p>
      </div>
    </div>
  );
};

export default Subscribe;