const Content = () => {
  return (
    <div className="px-4 text-black">
      <div className="max-w-screen-lg mx-auto space-y-4 rounded bg-[#e6dddd] mt-24 p-6">
        <h2 className="text-4xl font-semibold">Content</h2>
        <p>
          All the content is provided through the YouTube API. If you are the
          owner and, for some reason, would like us to delete it, please get in
          touch with us at content@wizmeek.com.
        </p>
      </div>
    </div>
  );
};
export default Content;
